<template class="orderdetail">
  <v-card>
    <v-card-title>出荷編集</v-card-title>
    <v-card-subtitle class="px-6" style="font-size:1rem;">
      <v-row>
        <v-col cols="6">
          <div>
            <span style="font-weight:bold;">出荷ID: </span>
            <span>{{ DeliveryForm.delv_id }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">送り先名: </span>
            <span>{{ DeliveryForm.addr_sei }} {{ DeliveryForm.addr_mei }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">電話番号: </span>
            <span>{{ DeliveryForm.addr_telno }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">送り先住所: </span>
            <span>{{ DeliveryForm.addr_addr1 }}</span><br>
            <span>{{ DeliveryForm.addr_addr2 }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">商品名: </span>
            <span>{{ DeliveryForm.product_name }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">配送セット数: </span>
            <span>{{ DeliveryForm.delv_qty }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">出荷期限: </span>
            <span>{{ DeliveryForm.delv_due_date }}</span>
          </div>
        </v-col>
        <v-col cols="6">
          <div>
            <span style="font-weight:bold;">注文番号: </span>
            <span>{{ DeliveryForm.order_no }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">注文日時: </span>
            <span>{{ DeliveryForm.order_dt }}</span>
          </div>
          <div>
            <span style="font-weight:bold;">注文者: </span>
            <span>{{ DeliveryForm.mbr_sei }} {{ DeliveryForm.mbr_mei }}</span>
          </div>
        </v-col>
      </v-row>
    </v-card-subtitle>

    <v-divider></v-divider>

    <v-card-title>出荷詳細</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="detailHeaders"
        :items="detailsList"
        :items-per-page="-1"
        hide-default-footer
        style="border:thin solid rgba(0, 0, 0, 0.14)"
        class="elevation-0"
      >
        <template v-slot:[`item.out_qty`]="{ item }">
          {{ formatData(item.out_qty) }}
        </template>
        <template v-slot:[`body.append`]>
          <tr>
            <td
              class="font-weight-bold"
              :colspan="3"
              style="background: #fafafa"
            >
              <label>合計数量</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ sumPrice }}</label>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-text>
      <div
        style="border:thin solid rgba(0, 0, 0, 0.14)"
      >
        <v-row align="center" class="px-2 ma-0 my-2">
          <v-col
            cols="12"
            md="2"
          >
            <label style="font-size:16px;color:#636363">出荷状態</label><span style="color: red">*</span>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0 my-0"
          >
            <v-autocomplete
              v-model="DeliveryForm.delv_status"
              :items="delvStatuses"
              color="#fa7a00"
              hide-details="auto"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters class="px-2 ma-3 my-4">
          <v-col
            cols="12"
            md="2"
          >
            <label for="note">処理メモ</label>
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <v-textarea
              v-model="orderProcMemo"
              hide-details="auto"
              color="#fa7a00"
              outlined
              :counter="100"
              dense
              rows="5"
              placeholder="処理メモ"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row no-gutters class="px-2 ma-3 my-4">
          <v-col
            cols="12"
            md="2"
          >
            <label for="note">備考</label>
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <v-textarea
              v-model="osDesc"
              hide-details="auto"
              color="#fa7a00"
              outlined
              :counter="100"
              dense
              rows="5"
              placeholder="備考"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col
            cols="12"
            class="pb-3"
            style="text-align:center"
          >
            <v-btn
              color="primary"
              class="mr-2"
              elevation="0"
            >
              <span style="color:white">保存</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        outlined
        elevation="0"
        :to="{ name: 'shipping-list', params: { query: this.$route.query } }"
      >
        <span>戻る</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex'

export default {
  components: {
  },
  data: () => ({
    OrderTotalColor: 'black',
    osDesc: '',
    orderProcMemo: '',
    delvStatuses: [],
    detailHeaders: [
      {
        text: 'No.',
        align: 'left',
        sortable: false,
        width: '10%',
        value: 'detal_id',
      },
      {
        text: '商品コード',
        align: 'left',
        sortable: false,
        width: '20%',
        value: 'item_code',
      },
      {
        text: '商品名',
        align: 'left',
        sortable: false,
        width: '40%',
        value: 'item_name',
      },
      {
        text: '出荷数量',
        align: 'right',
        sortable: false,
        width: '30%',
        value: 'out_qty',
      },
    ],
    tableList: [],
    detailsList: [],
    DeliveryForm: {
      addr_addr1: '',
      addr_addr2: '',
      addr_id: '',
      addr_mei: '',
      addr_mei_kana: '',
      addr_sei: '',
      addr_sei_kana: '',
      addr_telno: '',
      addr_zip: '',
      delv_due_date: '',
      delv_ead: '',
      delv_id: '',
      delv_pud: '',
      delv_qty: '',
      delv_status: '',
      details: [],
      ds_desc: '',
      mbr_mei: '',
      mbr_sei: '',
      ord_detail_id: '',
      ord_id: '',
      order_dt: '',
      order_no: '',
      packing: '',
      product_code: '',
      product_name: '',
    },
  }),
  computed: {
    ...mapState('shippingStore', ['selectedDeliveries', 'deliverieyStatus']),
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },

    sumPrice() {
      let sum = 0
      if (this.DeliveryForm.details) {
        this.DeliveryForm.details.forEach(a => {
          sum += a.out_qty
        })
      }

      return sum.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    },
  },
  watch: {
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('shippingStore', ['loadDeliveries', 'loadDeliveryStatusList']),
    loadData() {
      this.setOverlayStatus(true)
      this.loadDeliveries(this.$route.params.id).then(() => {
        this.DeliveryForm = {
          ...this.selectedDeliveries,
        }
        this.detailsList = [...this.selectedDeliveries.details]
        this.setOverlayStatus(false)
      })
      this.loadDeliveryStatusList(this.$route.params.id).then(() => {
        this.getStatuItem()
        this.setOverlayStatus(false)
      })
    },

    getStatuItem() {
      this.delvStatuses = this.deliverieyStatus.map(x => {
        const obj = {
        }
        obj.text = `${x.ds_code}: ${x.ds_desc}`
        obj.value = x.ds_code

        return obj
      })
    },
  },
}
</script>

<style scoped>
.theme--light.v-data-table >>> th {
  border-top: 0;
}
.order_proc_memo{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  width: 20rem;
}
.os_desc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  width: 10rem;
}
#OrderTotalColor {
  color: red !important;
}
#couponPriceColor {
  color: red !important;
}
</style>
